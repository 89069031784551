<style lang="less" scoped>
.aboutUs-details {
  width: 76.7%;
  height: 100%;
  max-width: 1104px;
  margin: 0 auto;
  padding: 196px 0;
  color: rgba(222, 230, 255, 1);
  &-first {
    width: 100%;
    display: flex;
    justify-content: space-between;
    &-left {
      width: 49.8%;
      img {
        width: 100%;
      }
    }
    &-right {
      width: 43.1%;
      p {
        margin: 0;
        font-family: "PingFangSC-Regular", "PingFang SC";
        color: rgba(222, 230, 255, 1);
        font-weight: 400;
        &:nth-child(1) {
          font-size: 30px;
          line-height: 42px;
          letter-spacing: 4px;
        }
        &:nth-child(2) {
          font-size: 10px;
          line-height: 14px;
          letter-spacing: 4px;
          margin: 5px 0 28px;
        }
        &:nth-child(3) {
          font-size: 16px;
          line-height: 40px;
          letter-spacing: 2px;
        }
      }
      .icon-container {
        display: flex;
      }
      .r-button {
        width: 140px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        cursor: pointer;
        margin-left: 38px;
        background: linear-gradient(
          211deg,
          rgba(248, 40, 108, 1) 0%,
          rgba(249, 98, 47, 1) 100%
        );
        border-radius: 29px;
      }
    }
  }
  &-second {
    width: 82%;
    height: 100%;
    max-width: 1104px;
    margin: 0 auto;
    padding-top: 108px;
    color: rgba(222, 230, 255, 1);
    display: flex;
    justify-content: space-around;
    &-left,
    &-right {
      width: 40%;
      text-align: left;
      font-size: 16px;
      font-weight: 400;
      line-height: 40px;
      letter-spacing: 2px;
    }
  }
  .normal {
    font-size: 16px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 2px;
  }
  &-third {
    margin-top: 101px;
    width: 100%;
    height: 698px;
    position: relative;
    .idc-fifth-container {
      width: 70%;
      margin-left: 15%;
      padding-top: 5%;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
  }
  /deep/
    .idc-fifth-container.swiper-container-initialized.swiper-container-horizontal
    > .swiper-button-next,
  .swiper-button-prev {
    width: 51px;
    height: 51px;
    background: rgba(222, 230, 255, 1);
    border-radius: 50%;
    color: #0f1118;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
    margin-left: 50px;
    margin-right: 50px;
    margin-top: -88px;
    &:after {
      font-size: 14px;
      font-weight: bolder;
    }
  }
}
</style>

<template>
  <div class="aboutUs-details">
    <div class="aboutUs-details-first">
      <div class="aboutUs-details-first-left">
        <img src="../assets/idc_s_1.png"
             alt="">
      </div>
      <div class="aboutUs-details-first-right">
        <p>
          大赛简介
        </p>
        <p>
          INTRODUCTION
        </p>
        <p>
          {{$t('IDC_forth5_1')}} <br />
          {{$t('IDC_forth5_2')}} <br />
          {{$t('IDC_forth5_3')}} <br />
          {{$t('IDC_forth5_4')}} <br />
          {{$t('IDC_forth5_5')}} <br />
        </p>
        <div class="icon-container">
          <div class="icon">
            <img src="../assets/weibo.png"
                 alt=""
                 style="width: 30px; cursor: pointer"
                 @click="linkTo('https://weibo.com/u/6096290535', '_blank')">
            <img src="../assets/weixin.png"
                 alt=""
                 style="width: 30px; marginLeft: 30px; cursor: pointer"
                 @click="linkTo('https://mp.weixin.qq.com/s/Xpwm4f_M0hJEGKGVZsf_6Q', '_blank')">
          </div>
          <div class="r-button"
               @click="toRoles">
            {{$t('rules')}}
          </div>
        </div>
      </div>
    </div>
    <p class="normal">
      <!-- {{$t('IDC_main')}}<br />
      {{$t('IDC_main1')}}<br />
      {{$t('IDC_main2')}}<br />
      {{$t('IDC_main2_1')}}<br />
      {{$t('IDC_main3')}}<br />
      {{$t('IDC_main4')}}<br />
      {{$t('IDC_main5')}}<br />
      {{$t('IDC_main6')}}<br />
      {{$t('IDC_main7')}}<br />
      {{$t('IDC_main8')}}<br />
      {{$t('IDC_main9')}}<br />
      {{$t('IDC_main10')}}<br />
      {{$t('IDC_main11')}}<br />
      {{$t('IDC_main12')}} -->
      为深入贯彻落实习近平总书记重要讲话精神，奋力夺取疫情防控和实现长三角经济社会发展目标“双胜利”，在长三角城市经济协调会办公室指导下，由长三角创意经济专业委员会（简称CECC）牵头组织长三角全球创意设计大赛及其专属“锦绣江南”名品榜活动。该活动已被列入长三角城市经济协调会2020年度重点合作事项清单，同时列入长三角城市经济协调会2021年度工作计划的重点事项和重要内容。2020年长三角全球创意设计大赛不仅被浙江省、专委联盟选为创新案例，大赛平台单位还协助大赛参赛选手产品进行落地实践，切实推动了成果转化。
      全球创意设计大赛定位为长三角世界级城市群建设的重要组成部分，将持续推动设计成果产业化转化和商业化运作，延伸设计产业价值链；同时，将继续加重“上海设计”国际化元素，站位全球设计价值链发声，构建“上海设计+长三角产业链”一体化高质量发展模式，汇聚全球创意英才，传播长三角“原创品牌”，向世界讲述“中国故事”。<br />
      大赛主题：<br />
      大赛持续以“Power of Design”为核心价值理念，主题定位于“绿色生活，城市IP”，倡导生活的可持续性设计应用，集聚世界创意资源，依据长三角城市特色打造城市独特IP，并将其设计实现商业转化。<br />
      合作机构：<br />
      大赛组委会将依托世界创意论坛（CWF）、英国格拉斯哥、蒙特利尔C2峰会、德国斯图加特创意大会、法国巴黎创意谷、Impact Hub Shanghai、全球最大孵化器Station F、东华-爱丁堡大学创意产业中心、西班牙Deusto大学创新孵化中心、丹麦科技大学SKYLAB、世界纺织大学联盟等国内外创新机构，进行全球发布并吸引独立设计师及企业，以及长三角创意设计类高校师生共同参与，长三角城市经济协调会成员城市人民政府支持共同完成。
    </p>
    <!-- <div class="aboutUs-details-second">
      <div class="aboutUs-details-second-left">
        <p class="normal">
          长三角城市经济协调会办公室指导 <br />
          长三角创意经济专业委员会（CECC）牵头<br />
          上海无畏创意经济区域合作促进中心<br />
          上海市创意产业协会联合承办<br />
          协同世界创意论坛（CWF）<br />
          蒙特利尔C2峰会<br />
          德国斯图加特创意大会<br />
        </p>
      </div>
      <div class="aboutUs-details-second-right">
        <p class="normal">
          爱丁堡大学及其产业中心<br />
          东华大学世界纺织高校教育联盟等国内外机构<br />
          全球发布<br />
          全球优秀独立设计师、青年创客、创新企业<br />
          设计类高校师生共同参与<br />
          长三角城市经济协调会成员城市<br />
          人民政府支持共同完成<br />
        </p>
      </div>
    </div> -->
    <div class="aboutUs-details-third">
      <div class="idc-fifth-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <img src="../assets/idc_s_2.png"
                 alt="">
          </div>
          <div class="swiper-slide">
            <img src="../assets/idc_s_3.png"
                 alt="">
          </div>
          <div class="swiper-slide">
            <img src="../assets/idc_s_4.png"
                 alt="">
          </div>
          <div class="swiper-slide">
            <img src="../assets/idc_s_5.jpg"
                 alt="">
          </div>
          <div class="swiper-slide">
            <img src="../assets/idc_s_6.png"
                 alt="">
          </div>
        </div>
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </div>
    </div>
    <Common-activies></Common-activies>
    <Common-footer></Common-footer>
  </div>
</template>

<script>
import Swiper from 'swiper';
import 'swiper/css/swiper.min.css';
import CommonActivies from '../components/common-activities-c';
import CommonFooter from '../components/common-footer';
export default {
  name: 'aboutUs-details',
  data () {
    return {
    }
  },
  created () {
  },
  mounted () {
    new Swiper('.idc-fifth-container', {
      autoplay: {
        disableOnInteraction: false,
        delay: 2000
      },
      loop: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    })
  },
  methods: {
    linkTo (url, type) {
      if (type) {
        window.open(url, type);
      } else {
        this.$router.push({
          name: url
        })
      }
    },
    toRoles () {
      this.$router.push({
        name: 'personCenterCopy',
        // params: {
        //   from: 'IDCRole'
        // }
      })
    }
  },
  components: {
    CommonFooter,
    CommonActivies
  },
}
</script>
